import {toLogin} from '../libs/login';
import Cache from "@/utils/cache.js";
import store from '@/store';
import Vue from "vue";
import {head} from "@dcloudio/vue-cli-plugin-uni/packages/postcss/tags";

const cachePrefix = process.env.VUE_APP_REQUEST_CACHE_PREFIX;

let baseUrl = process.env.VUE_APP_BASE_URL;
let isShowLoading = false;








/**
 * 发送请求
 *
 * @param method 发送方式
 * @param url 发送地址
 * @param data 发送数据
 * @param loading loading
 * @param cacheSecond 缓存秒数
 * @returns {Promise<unknown>}
 */
function service(method, url, data, loading, cacheSecond) {
    let header = baseHeader();
    header["Content-Type"] = "application/json;charset=utf-8"
    Vue.prototype.$log.debug("request Data", url, data)

    if (method === "POST" || method === "PUT") {
        header["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
        data = unidimensionalJson(data)
    }

    // get请求映射params参数
    if (method === 'GET' && data) {
        url = url + '?' + tansParams(data);
        url = url.slice(0, -1);
        data = {};
    }

    // 打开 loading
    if (loading) {
        showLoading({title: '加载中', mask: true});
    }

    return new Promise((success, fail) => {
        if (cacheSecond && cacheSecond > 0) {
            const cacheData = Cache.get(url, cachePrefix);
            if (cacheData) {
                success(cacheData);
                return
            }
        }
        try {
            uni.request({
                url: baseUrl + url,
                method: method || 'GET',
                header: header,
                timeout: 120000, // 超时时间，单位 ms
                data: method === 'GET' ? void 0 : data,
                params: method === 'GET' ? data : void 0,
                success: (res) => {
                    hideLoading();
                    Vue.prototype.$log.debug("response", res)
                    const statusCode = res.statusCode || 200;
                    const response = res.data;
                    const msg = response.msg || '系统错误';
                    if (200 !== statusCode) {
                        Vue.prototype.$log.error("系统异常", res)
                        Vue.prototype.$util.waitTips(300, {title: msg});
                        return
                    }

                    store.commit('SAVE_TRACE_ID', response.traceId);
                    const code = response.code;

                    switch (code) {
                        case "SUCCESS":
                            Vue.prototype.$log.debug("response data", url, response.data)
                            success(response.data);
                            if (cacheSecond && cacheSecond > 0) {
                                Cache.set(url, response.data, cacheSecond, cachePrefix);
                            }
                            break
                        case "A0220":
                        case "A0242":
                        case "A0244":
                        case "A0243":
                            toLogin();
                            break
                        case "A0230":
                        case "D0101":
                            fail(response);
                            break
                        default:
                            fail(response);
                            Vue.prototype.$util.waitTips(300, {title: msg});
                            break
                    }
                },
                fail: (err) => {
                    fail({});
                    Vue.prototype.$util.waitTips(300, {title: "未知错误,请联系管理员"});
                    Vue.prototype.$log.error('Request failed', err);
                },
                complete: (err) => {
                    hideLoading();
                    // 无论请求成功或失败都会执行的回调函数
                    fail(undefined);
                }
            });
        } catch (e) {
            hideLoading();
            fail({});
            Vue.prototype.$log.error("response fails", e)
            if (e.data instanceof String) {
                Vue.prototype.$util.waitTips(300, {title: e.data});
            } else {
                Vue.prototype.$util.waitTips(300, {title: "未知错误,请联系管理员"});
            }
        }
    });
}

export function hideLoading() {
    if(isShowLoading) {
        try {
            uni.hideLoading(); // 如果出错，不会中断后续代码
        } catch (error) {
        }
    }
}

export function showLoading(options) {
    if(isShowLoading) {
        return
    }
    uni.showLoading(options);
    isShowLoading = true;
}

export function fileUpload(url, file, loading) {
    const header = baseHeader();
    // 删除 Content-type 的设置
    Vue.prototype.$log.debug("request url", url)

    // 打开 loading
    if (loading) {
        showLoading({title: '加载中', mask: true});
    }

    return new Promise((success, fail, data) => {
        try {
            uni.uploadFile({
                url: baseUrl + url,
                method: 'POST',
                header: header,
                filePath: file,
                name: 'file',
                formData: data || {},
                success: (res) => {
                    hideLoading();
                    Vue.prototype.$log.debug("response", res)
                    const statusCode = res.statusCode || 200;
                    if (200 !== statusCode) {
                        Vue.prototype.$log.error("系统异常", res)
                        Vue.prototype.$util.waitTips(300, {title: '系统异常'});
                        fail({});
                        return
                    }
                    const response = JSON.parse(res.data);
                    const msg = response.msg || '系统错误';
                    store.commit('SAVE_TRACE_ID', response.traceId);

                    const code = response.code;
                    switch (code) {
                        case "SUCCESS":
                            Vue.prototype.$log.debug("response data", response.data)
                            success(response.data);
                            break
                        case "A0220":
                        case "A0242":
                        case "A0244":
                        case "A0243":
                            toLogin();
                            break
                        case "D0101":
                        case "A0230":
                            fail(response);
                            break
                        default:
                            Vue.prototype.$util.Tips({title: msg});
                            fail(response);
                            break
                    }
                },
                fail: (msg) => {
                    hideLoading();
                    fail({});
                    Vue.prototype.$util.waitTips(300, {title: "未知错误,请联系管理员"});
                    Vue.prototype.$log.error('Request failed', msg);
                }
            })
        } catch (e) {
            hideLoading();
            fail({});
            Vue.prototype.$log.error("response fails", e)
            if (e.data instanceof String) {
                Vue.prototype.$util.waitTips(300, {title: e.data});
            } else {
                Vue.prototype.$util.waitTips(300, {title: "未知错误,请联系管理员"});
            }
        }
    });
}


/**
 * 文件下载
 * @param method 请求方式
 * @param url 请求地址
 * @param data 参数
 * @param loading 是否需要 loading
 * @param openOrSave 打开或保存, true 打开; false 保存
 * @param fileName 文件名
 * @returns {Promise<unknown>}
 */
export function fileDown(method, url, data, loading, openOrSave, fileName) {
    const header = baseHeader();
    Vue.prototype.$log.debug("request url", url)
    header["Accept"] = buildAccept(fileName);

    if (method === "POST" || method === "PUT") {
        header["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
        data = unidimensionalJson(data)
    }

    // get请求映射params参数
    if (method === 'GET' && data) {
        url = url + '?' + tansParams(data);
        url = url.slice(0, -1);
        data = {};
    }

    // 打开 loading
    if (loading) {
        showLoading({title: '加载中', mask: true});
    }

    return new Promise((success, fail) => {
        try {
            uni.downloadFile({
                url: baseUrl + url,
                method: method || 'GET',
                header: header,
                data: method === 'GET' ? void 0 : data,
                params: method === 'GET' ? data : void 0,
                success: (res) => {
                    hideLoading();
                    const statusCode = res.statusCode || 200;
                    if (200 !== statusCode) {
                        Vue.prototype.$util.waitTips(300, {title: '文件下载失败'});
                        return
                    }
                    // 是图片
                    if (isImage(fileName)) {
                        if(openOrSave){
                            Vue.prototype.$util.Tips({title: '请长按图片保存'});
                            uni.previewImage({
                                urls: [res.tempFilePath]
                            })
                        }
                        success(res);
                    } else {
                        if(openOrSave) {
                            uni.openDocument({
                                filePath: res.tempFilePath,
                                showMenu: true,
                                fileType: getFileTypeByExtension(fileName),
                                success: function () {
                                    success(res);
                                },
                                fail: function (err) {
                                    Vue.prototype.$util.waitTips(300, {title: '文件打开失败'});
                                    Vue.prototype.$log.error('文件打开失败', err);
                                }
                            });
                        } else {
                            uni.saveFile({
                                tempFilePath: res.tempFilePath,
                                success: (result) => {
                                    const savedFilePath = res.savedFilePath;
                                    success(savedFilePath);
                                },
                                fail: (err) => {
                                    Vue.prototype.$util.waitTips(300, {title: '保存文件失败'});
                                    Vue.prototype.$log.error('保存文件失败', err);
                                }
                            });
                        }
                    }
                },
                fail: (err) => {
                    Vue.prototype.$util.waitTips(300, {title: "未知错误,请联系管理员"});
                    Vue.prototype.$log.error('Request failed', err);
                },
                complete: (err) => {
                    hideLoading();
                    // 无论请求成功或失败都会执行的回调函数
                    console.debug('Request complete');
                }
            });
        } catch (e) {
            hideLoading();
            Vue.prototype.$log.error("response fails", e)
            if (e.data instanceof String) {
                Vue.prototype.$util.waitTips(300, {title: e.data});
            } else {
                Vue.prototype.$util.waitTips(300, {title: "未知错误,请联系管理员"});
            }
        }
    });
}

/**
 *  复杂json转为一维的
 * @param data 数据值
 * @param form FormData实例
 * @param keyPrefix key 前缀
 * @param keySuffix key 后缀
 * @returns {*|FormData}
 * @constructor
 使用：unidimensionalJson("", a, null);
 */
function unidimensionalJson(data, form, keyPrefix, keySuffix) {
    if (data === undefined) {
        return form;
    }
    const dateKeys = Object.keys(data);
    if (dateKeys.length === 0) {
        return form;
    }
    if (keyPrefix === undefined) {
        keyPrefix = '';
    }
    if (keySuffix === undefined) {
        keySuffix = '';
    }
    form = form || {}
    dateKeys.forEach((key) => {
        let val = data[key]
        if (val === undefined) {
        } else if (val instanceof Array) { // 数组
            let pk = keyPrefix ? `${keyPrefix}${key}${keySuffix}[` : `${key}${keySuffix}[`
            unidimensionalJson(val, form, pk, ']')
        } else if (val instanceof Object) { // 数组
            let pk = keyPrefix ? `${keyPrefix}${key}${keySuffix}.` : `${key}${keySuffix}.`
            unidimensionalJson(val, form, pk)
        } else {
            form[`${keyPrefix}${key}${keySuffix}`] = val
        }
    });
    return form
}

/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
    let result = ''
    for (const propName of Object.keys(params)) {
        const value = params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && value !== "" && typeof (value) !== "undefined") {
            if (typeof value === 'object') {
                for (const key of Object.keys(value)) {
                    if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
                        let params = propName + '.' + key;
                        var subPart = encodeURIComponent(params) + "=";
                        result += subPart + encodeURIComponent(value[key]) + "&";
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + "&";
            }
        }
    }
    return result
}

/**
 * get 请求操作
 * @param url 路径
 * @param params 参数
 * @param loading 参数
 * @param cacheSecond 缓存的秒数
 * @returns {AxiosPromise}
 */
export function get(url, params, loading, cacheSecond) {
    return service('GET', url, params, loading || false, cacheSecond)
}

/**
 * post 请求操作
 * @param url 路径
 * @param data 参数
 * @param loading 参数
 * @param cacheSecond 缓存的秒数
 * @returns {AxiosPromise}
 */
export function post(url, data, loading, cacheSecond) {
    return service('POST', url, data, loading || false, cacheSecond)
}

/**
 * delete 请求操作
 * @param url 路径
 * @param loading 参数
 * @param cacheSecond 缓存的秒数
 * @returns {AxiosPromise}
 */
export function del(url, loading, cacheSecond) {
    return service('DELETE', url, {}, loading || false, cacheSecond)
}

/**
 * delete 请求操作
 * @param url 路径
 * @param data 参数
 * @param loading 参数
 * @param cacheSecond 缓存的秒数
 * @param isIntactUrl 是完整路径
 * @returns {AxiosPromise}
 */
export function put(url, data, loading, cacheSecond) {
    return service('PUT', url, data, loading || false, cacheSecond)
}

/**
 * 头部数据
 * @returns {{Project: string, Version: string, Driver: string, TraceId: *, "Content-Type": string}}
 */
export function baseHeader() {
    let header = {
        'Project': process.env.VUE_APP_PROJECT_ID,
        'Version': process.env.VUE_APP_VERSION,
        'Driver': Vue.prototype.$util.getDriver(),
        'TraceId': store.state.app.traceId,
        'Accept': "application/json"
    }
    if (store.state.app.token) header["Authorization"] = 'Bearer ' + store.state.app.token;
    return header;
}

export function buildAccept(fileName) {
    // 获取文件扩展名（去掉文件名中的其他部分，获取后缀）
    const fileExtension = fileName.split('.').pop().toLowerCase();
    let typeMap = {
        "pdf": "application/pdf",
        "xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "xls": "application/vnd.ms-excel",
        "csv": "text/csv",
        "png": "image/png",
        "jpeg": "image/jpeg",
        "jpg": "image/jpeg",
        "gif": "image/gif",
        "webp": "image/webp",
        "audio": "audio/*",
        "mp3": "audio/mpeg",
        "mp4": "video/mp4",
        "avi": "video/avi",
        "txt": "text/plain",
        "json": "application/json",
        "xml": "application/xml",
        "bin": "application/octet-stream"
    }

    // 返回对应的 fileType，如果没有匹配，返回 "unknown"
    return typeMap[fileExtension] || "unknown";
}

export function getFileTypeByExtension(fileName) {
    // 获取文件扩展名（去掉文件名中的其他部分，获取后缀）
    const fileExtension = fileName.split('.').pop().toLowerCase();

    // 文件扩展名与 fileType 映射关系
    const fileTypeMap = {
        "pdf": "pdf",             // PDF 文件
        "doc": "word",            // Word 旧版文件
        "docx": "word",           // Word 新版文件
        "xlsx": "excel",          // Excel 文件
        "xls": "excel",           // Excel 旧版文件
        "csv": "excel",           // CSV 文件
        "ppt": "ppt",             // PowerPoint 文件
        "pptx": "ppt",            // PowerPoint 文件
        "txt": "text",            // 文本文件
        "jpg": "image",           // 图片文件
        "jpeg": "image",          // 图片文件
        "png": "image",           // 图片文件
        "gif": "image",           // 图片文件
        "bmp": "image",           // 图片文件
        "webp": "image",          // 图片文件
        "zip": "zip",             // 压缩文件
        "rar": "zip",             // 压缩文件
        "json": "json",           // JSON 文件
        "xml": "xml",             // XML 文件
    };

    // 返回对应的 fileType，如果没有匹配，返回 "unknown"
    return fileTypeMap[fileExtension] || "unknown";
}

export function isImage(fileName) {
    // 获取文件扩展名（去掉文件名中的其他部分，获取后缀）
    const fileExtension = fileName.split('.').pop().toLowerCase();

    // 文件扩展名与 fileType 映射关系
    const fileTypeMap = [
        'jpg','png','jpeg','gif'
    ];

    // 返回对应的 fileType，如果没有匹配，返回 "unknown"
    return fileTypeMap.includes(fileExtension);
}